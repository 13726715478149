<template>
  <div class="useral-insert-organization-ticket">
    <div class="view-side mt-3 sm:mt-0">
      <div class="view-box">
        <div class="select-type" v-if="tabIndex === 0">
          <h2 class="select-type-label">
            {{ $t('tickets.organization.labels.selectType') }}
          </h2>

          <template v-for="(ticketType, key) in ticketTypes">
            <div :key="key" class="ticket-type" @click="handleSelectTicketType(ticketType)">
              <div class="ticket-content">
                <h3 class="ticket-title">
                  {{ ticketType.title }}
                </h3>
                <p class="ticket-description">
                  {{ ticketType.description }}
                </p>
              </div>
              <div class="ticket-arrow">
                <custom-icon icon="CHEVRON_LEFT" color-code="#000" />
              </div>
            </div>
          </template>
        </div>
        <vs-row v-if="tabIndex === 1">
          <vs-col>
            <div>
              <div class="select-ticket-user">
                <custom-validate-input :label="$t('tickets.organization.labels.user')"
                                       :styles="{width: '100%'}"
                                       align="right"
                                       v-model="ticket.user.name"/>

                <font-awesome-icon class="suggest-modal-button"
                                   icon="tasks"
                                   @click="suggestUserPromptStatus = true"/>

                <font-awesome-icon class="new-suggest-modal-button"
                                   icon="plus"
                                   @click="newSuggestUserPromptStatus = true"/>

                <!-- users List prompt -->
                <vs-prompt
                  class="very-big-prompt p-0"
                  :buttons-hidden="true"
                  title=""
                  :active.sync="suggestUserPromptStatus"
                  @close="suggestUserPromptStatus = true">

                  <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
                    <vs-row>
                      <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
                        <div @click="handleClick('selectUsers')">
                          <custom-icon icon="CHECK" color="success"/>
                        </div>
                      </vs-col>

                      <vs-spacer/>

                      <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
                        {{ $t('users.select.title')}}
                      </vs-col>

                      <vs-spacer/>

                      <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
                        <div @click="suggestUserPromptStatus = false">
                          <custom-icon icon="TIMES-CIRCLE" color="danger"/>
                        </div>
                      </vs-col>
                    </vs-row>
                  </div>

                  <div class="prompt-content py-2 px-3">
                    <template>
                      <keep-alive>
                        <select-users :multi-select="false" :default-filter="{roleId: '2,3'}" @selected="handleSuggestUserSelected($event)"/>
                      </keep-alive>
                    </template>
                  </div>

                </vs-prompt>

                <!-- insert new user prompt -->
                <vs-prompt
                  class="prompt p-0"
                  :buttons-hidden="true"
                  title=""
                  :active.sync="newSuggestUserPromptStatus"
                  @close="newSuggestUserPromptStatus = true">

                  <div class="prompt-header p-3 w-full" :class="[!this.$vs.rtl ? 'rtl-only' : '']">
                    <vs-row>
                      <vs-col class="w-1/5 useral-font-weight-medium text-success cursor-pointer">
                        <div @click="handleClick('insertUserBTN')">
                          <custom-icon icon="CHECK" color="success"/>
                        </div>
                      </vs-col>

                      <vs-spacer/>

                      <vs-col class="w-1/2 text-center useral-font-weight-bold text-md">
                        {{ $t('users.user.insert.title') }}
                      </vs-col>

                      <vs-spacer/>

                      <vs-col class="w-1/5 text-right useral-font-weight-medium text-danger cursor-pointer">
                        <div @click="newSuggestUserPromptStatus = false">
                          <custom-icon icon="TIMES-CIRCLE" color="danger"/>
                        </div>
                      </vs-col>
                    </vs-row>
                  </div>

                  <div class="prompt-content">
                    <template>
                      <keep-alive>
                        <insert-user :in-modal="true" @inserted="handleNewUserInserted($event)"/>
                      </keep-alive>
                    </template>
                  </div>

                </vs-prompt>
              </div>
              <custom-validate-input :label="$t('tickets.organization.labels.subject')"
                                     :placeholder="$t('tickets.organization.placeholder.subject')"
                                     :classes="{'w-full': true}"
                                     align="right"
                                     rtl
                                     v-model="ticket.subject"/>

              <custom-validate-textarea :label="$t('tickets.organization.labels.message')"
                                        :placeholder="$t('tickets.organization.placeholder.message')"
                                     :classes="{'w-full': true}"
                                     align="right"
                                     rtl
                                     v-model="ticket.message"/>

              <div class="attachment-inputs-section">
                <div class="attach-inputs">
                  <template v-for="(attach, key) in ticket.attaches">
                    <custom-file-input :label="$t('tickets.organization.labels.dynamicAttach', {number: key + 1})"
                                       :is-empty="true"
                                       :key="key"
                                       logo-type="formData"
                                       accept-type="*/*"
                                       :styles="{width: '100%'}"
                                       :preview="false"
                                       :upload-text="$t('tickets.organization.notifications.attachUpload')"
                                       v-model="attach.file"
                                       :disabled="false"/>
                  </template>
                </div>
                <button class="insert-new-attach-btn" @click="handleInsertAttachInput">
                  {{ $t('tickets.organization.labels.insertAttach') }}
                </button>
              </div>
            </div>
          </vs-col>
        </vs-row>
      </div>
    </div>

    <vs-button v-show="false"
               id="insertTicketBTN"
               @click="sendData"/>
  </div>
</template>

<script>
  import axios from 'axios'
  import CustomValidateInput from '../../../../../components/customInput/customValidateInput'
  import CustomValidateTextarea from '../../../../../components/customInput/customValidateTextarea'
  import CustomDialog from '../../../../../components/customDialog/customDialog'
  import {checkUserPermissions} from '../../../../../assets/js/functions'
  import CustomSelect from '../../../../../components/customSelect/customSelect'
  import CustomFileInput from "../../../../../components/customInput/customeFileInput";
  import CustomProfileImageInput from "../../../../../components/customInput/customProfileImageInput";
  import CustomIcon from "../../../../../components/customIcon/customIcon";
  import {insertOrganizationTicket} from "../../../../../http/requests/tickets";
  import SelectUsers from "../../../customers/select/selectUsers";
  import InsertUser from "../../../settings/employee/insert/insertEmployee2";

  export default {
    name: 'insertOrganizationTicket',
    components: {
      InsertUser,
      SelectUsers,
      CustomIcon,
      CustomProfileImageInput,
      CustomFileInput,
      CustomValidateTextarea,
      CustomSelect,
      CustomDialog,
      CustomValidateInput
    },
    data() {
      return {
        tabIndex: 1,
        suggestServicePromptStatus: false,
        suggestUserPromptStatus: false,
        newSuggestUserPromptStatus: false,
        ticketTypes: [
          {
            title: 'مشاوره خرید',
            description: 'در صورت تمایل به دریافت خدمات ارائه شده در یوزرال و هر گونه سوال، درخواست خود را از این بخش مطرح نمایید',
            value: 1
          },
          {
            title: 'درخواست های مرتبط با حساب کاربری',
            description: 'تمامی درخواست های مرتبط با حساب کاربری خود را از این طریق پیگیری نمایید.',
            value: 1
          },
          {
            title: 'همکاری در فروش',
            description: 'در صورت هرگونه سوال و یا درخواست بررسی و واریز مبلغ همکاری در فروش، درخواست خود را از این بخش ارسال نمایید.',
            value: 1
          }
        ],
        ticket: {
          user: {
            id: 0,
            name: {
              value: '',
              isValid: true
            }
          },
          subject: {
            value: '',
            isValid: false
          },
          service: {
            id: 0,
            name: {
              value: '',
              isValid: true
            }
          },
          message: {
            value: '',
            isValid: false
          },
          attaches: [{
            file: {
              value: '',
              isValid: true
            }
          }]
        }
      }
    },
    created() {
    },
    methods: {
      checkUserPermissions(permission) {
        return checkUserPermissions(permission)
      },
      handleClick(id) {
        document.getElementById(id).click()
      },
      handleSelectTicketType (ticketType) {
        this.tabIndex = 1
      },
      handleInsertAttachInput () {
        this.ticket.attaches.push({
          file: {
            value: '',
            isValid: true
          }
        })
      },
      handleSuggestUserSelected(list) {
        let user = {}
        list.forEach((data) => {
          if (!data.hasOwnProperty('show') || data.show) {
            user = {
              id: data.id,
              name: {
                value: data.name,
                isValid: true
              },
              gender: data.gender === this.$t('genderTypes.man') ? 1 : 2
            }
          }
        })
        this.ticket.user = user
        this.suggestUserPromptStatus = false
      },
      handleNewUserInserted(user) {
        this.ticket.user.id = user.id
        this.ticket.user.name.value = `${user.name} ${user.family}`
        this.newSuggestUserPromptStatus = false
      },
      sendData() {
        if (this.ticket.user.id === 0) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('tickets.organization.validators.user'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }

        if (!this.ticket.subject.isValid) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('tickets.organization.validators.subject'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }

        if (!this.ticket.message.isValid) {
          this.$vs.notify({
            title: this.$t('alert.error.title'),
            text: this.$t('tickets.organization.validators.message'),
            icon: 'icon-alert-circle',
            iconPack: 'feather',
            time: 2400,
            color: 'danger'
          })
          return false
        }

        let attaches = []
        let formData = new FormData()
        let attachCount = 0
        this.ticket.attaches.forEach((attach) => {
          if (attach.file.value) {
            attaches.push(formData)
            formData.append(`attachments[${attachCount}]`, attach.file.value);
            attachCount++
          }
        })

        formData.append('user_id', this.ticket.user.id);
        formData.append('subject', this.ticket.subject.value);
        formData.append('message', this.ticket.message.value);

        insertOrganizationTicket(formData).then(() => {
          this.$vs.notify({
            title: this.$t('alert.message.title'),
            text: this.$t('tickets.organization.notifications.insert.success'),
            icon: 'icon-check',
            iconPack: 'feather',
            time: 2400,
            color: 'success'
          })
          this.$emit('insert')
        }).catch((error) => {
          if (axios.isCancel(error)) {
            this.$vs.notify({
              title: this.$t('alert.duplicateRequest.title'),
              text: this.$t('alert.duplicateRequest.message'),
              icon: 'icon-alert-circle',
              iconPack: 'feather',
              time: 2400,
              color: 'warning'
            })
          } else {
            const error_mapper = {
              'user_id': this.$t('tickets.organization.notifications.parseError.user'),
              'service_id': this.$t('tickets.organization.notifications.parseError.service'),
              'subject': this.$t('tickets.organization.notifications.parseError.subject'),
              'message': this.$t('tickets.organization.notifications.parseError.message'),
              'attachments': this.$t('tickets.organization.notifications.parseError.attachments'),
            }

            switch (error.response.status) {
              case 422:
                Object.keys(error.response.data.errors).forEach((error_key) => {
                  const err = error_key.toString().split('.')
                  if (error_mapper[err[err.length - 1]]) {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: error_mapper[err[err.length - 1]],
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })
                    error_mapper[err[err.length - 1]] = null
                  } else if (error_mapper[err[0]]) {
                    this.$vs.notify({
                      title: this.$t('alert.error.title'),
                      text: error_mapper[err[0]],
                      color: 'danger',
                      icon: 'icon-alert-circle',
                      iconPack: 'feather',
                      time: 2400
                    })
                    error_mapper[err[0]] = null
                  }
                })
                break

              default:
                this.$vs.notify({
                  title: this.$t('alert.error.title'),
                  text: this.$t('tickets.organization.notifications.insert.error'),
                  color: 'danger',
                  icon: 'icon-alert-circle',
                  iconPack: 'feather',
                  time: 2400
                })
                break
            }
          }
        })
      }
    }
  }
</script>

<style lang="scss">
  .useral-insert-organization-ticket {
    width: 100%;
    height: 100%;
    display: flex;

    @media (max-width: 767px) {
      display: block;
    }

    .menu-side {
      width: 200px;
      min-width: 200px;
      height: 100%;
      padding: 0 10px 0 0;

      @media (max-width: 767px) {
        width: 100%;
        min-width: 100%;
        padding: 0;
        height: fit-content;
      }

      ul {

        li {
          position: relative;
          border: 1px solid #cecece;
          border-radius: .5rem;
          margin-bottom: 10px;
          padding: 0 10px;
          line-height: 40px;
          cursor: pointer;
          transition: all 0.3s ease;

          .icon-chevron-left {
            position: absolute;
            height: fit-content;
            top: 0;
            bottom: 0;
            right: 6px;
            margin: auto;
          }

          &:hover {
            transform: translateX(5px);
          }
        }
      }
    }

    .view-side {
      flex-grow: 1;
      height: 100%;
      /*border: 1px solid #cecece;*/
      padding: 5px;
      border-radius: .5rem;
      overflow-y: auto;

      .view-box {
        /*min-height: 300px;*/
        width: 100%;
        height: 100%;
        padding: 5px;
        overflow: auto;

        .select-type {
          .select-type-label {
            font-size: 17px;
            margin-bottom: 10px;
          }

          .ticket-type {
            display: flex;
            align-items: center;
            border: 1px solid #cdcdcd;
            border-radius: 0.5rem;
            padding: 15px 10px;
            margin-bottom: 10px;
            transition: all ease 0.5s;
            height: calc(100% - 50px);
            overflow-y: auto;

            .ticket-content {
              width: calc(100% - 40px);

              .ticket-title {
                font-size: 16px;
                margin-bottom: 10px;
              }

              .ticket-description {
                font-size: 14px;
              }
            }

            .ticket-arrow {
              width: 40px;
            }

            &:hover {
              cursor: pointer;
              background-color: #f5f5f5;
              transform: translateX(5px);
            }
          }
        }

        .select-ticket-user {
          position: relative;

          .suggest-modal-button, .new-suggest-modal-button {
            position: absolute;
            bottom: 0;
            right: 35px;
            top: 5px;
            width: 25px;
            height: 25px;
            padding: 5px;
            border: 1px solid #00bcd4;
            border-radius: .5rem;
            color: #00bcd4;
            background: white;
            cursor: pointer;
          }

          .new-suggest-modal-button {
            right: 6px;
            border: 1px solid #28C76F;
            color: #28C76F;
          }
        }

        .select-service {
          position: relative;

          .suggest-modal-button, .new-suggest-modal-button {
            position: absolute;
            bottom: 0;
            right: 6px;
            top: 4px;
            width: 15px;
            height: 15px;
            padding: 5px;
            border: 1px solid #00bcd4;
            border-radius: .5rem;
            color: #00bcd4;
            background: white;
            cursor: pointer;
          }
        }

        .attachment-inputs-section {
          display: flex;
          align-items: flex-start;

          .attach-inputs {
            flex-grow: 1;
            margin-right: 10px;
          }

          .insert-new-attach-btn {
            margin-top: 15px;
            padding: 5px 10px;
            border-radius: 0.5rem;
            border: 1px solid #cdcdcd;
            background-color: inherit;
            min-height: 36px;

            &:hover {
              background-color: #f0f0f0;
              cursor: pointer;
            }
          }
        }

        .custom-input:last-child {
          margin-bottom: 0;
        }

        div.edit-user-field {
          position: relative;

          span.label {
            font-size: 12px;
            position: absolute;
            top: -10px;
            left: 7px;
            z-index: 10000;
            background: #ffffff;
            padding: 0 5px;
          }

          .useral-custom-element-select {
            margin: 15px 0;

            .selected {
              height: 35px;
              line-height: 35px;
              font-size: 13px;
            }

            .items {
              line-height: 35px;

              div {
                height: 35px;
                font-size: 13px;
              }
            }
          }
        }

        .error-alert {
          display: inline-block;
          border: 1px solid #ffba00;
          border-radius: .5rem;
          color: #ffba00;
          padding: 0 10px;
          line-height: 50px;
          height: 50px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 15px;

          i {
            font-size: 15px;
          }
        }

        &::-webkit-scrollbar {
          display: block;
          border-radius: .2rem;
          width: 10px;
          background: #f9f9fd;
          right: 10px;
        }

        &::-webkit-scrollbar-thumb {
          display: block;
          border-radius: .2rem;
          background: #cecece;
        }
      }
    }
  }
</style>
